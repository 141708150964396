import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-unificator/services';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { GlobalEventsService } from './global-events.service';
import { GroupsService } from './groups.service';
import { LanguageService } from './language/language.service';
import { PlatformService } from './platform.service';
import { StaticContentService } from './static-content.service';
import { UserService } from './user/user.service';
import { HostRequestsInterceptor } from '../interceptors/host-request.interceptor';

export const EXCLUDE_PUSH_PROMPT_PAGES = [
  '/404',
  '/403',
  'login',
  'registration'
]

declare global {
  interface Window {
    OneSignal: any;
    OneSignalDeferred: any;
  }
}

declare var OneSignal;

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  private _appId = 'cca10593-3f36-4315-b3c5-1d6209c33c5a';

  constructor(
    private _platform: PlatformService,
    private _globalEvents: GlobalEventsService,
    private _cookie: CookieService,
    private _group: GroupsService,
    private _language: LanguageService,
    private _user: UserService,
    private _static: StaticContentService,
    private _hostInterceptor: HostRequestsInterceptor

  ) {}

  /**
   * Get one signal id from CMS
   */
  handleId() {
    if (this._platform.isBrowser) {
      this._static.item({ slug: 'one-signal-ids' }).pipe(
        filter(item => !!item?.length),
        first(),
        map(item => item[0]?.Grouplist),
        map(mirrors => {
          const hostname = this._hostInterceptor.getDomainName().split('.')[1];
          return mirrors[hostname]?.id?.trim() || this._appId;
        }),
        tap((appId: string) => this._onInit(appId))
      ).subscribe();
    }
  }

  /**
   * Init one signal and apply id from CMS
   */
  private _onInit(id: string): void {
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async () => {
      await OneSignal.init({
        appId: id,
        notifyButton: {
          enable: true
        },
        serviceWorkerParam: { scope: '/push/onesignal/' },
        serviceWorkerPath: "push/onesignal/OneSignalSDKWorker.js",
      }).then(() => {
        this._autoUpdateUser();
        this._setExternalId();
      });
    });
  }

  // This function will work only if Onesignal init. So it will don`t work for localhost or stage
  // And will not send test users data. To make it work change in Onesignal admin panel in app test url to localhost
  private _autoUpdateUser() {
    this._globalEvents.routerNavigationEnd$.pipe(
      distinctUntilChanged(),
      tap((data) => {
        OneSignal.push(() => {
          this._setUserTags(this._createUserTagsObj());
        });
      })
    ).subscribe();
  }

  private _setUserTags(data) {
    OneSignal?.User?.addTags(data)
  }

  private _createUserTagsObj() {
    if (this._user.auth) {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        isUserHadDeposit: (this._user.hasDeposit || this._user.accountList.some((e => e.amount > 0)))?.toString(),
        userLanguage: this._language.current,
        isHaveBalance: this._user.accountList.some((e => e.amount > 0))?.toString(),
      };
    } else {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        userLanguage: this._language.current,
      };
    }
  }


  private _setExternalId() {
    OneSignal.push(async () => {
      if (OneSignal?.User?.PushSubscription?.id) {
        OneSignal.login(OneSignal.User.PushSubscription.id);
      }
    });
  }
}
